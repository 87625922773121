import { TagBlack, TagPeach, TagDropdown } from '/features/buildingBlocks/Tag'

import styles from './MetaTags.css'

export function MetaTags({ tags, label = undefined }) {
  return (
    <MetaTagsBase {...{ label }}>
      <Tags {...{ tags }} />
    </MetaTagsBase>
  )
}

export function MetaTagsCentered({ tags, label = undefined }) {
  return (
    <MetaTagsBase className={styles.componentCentered} {...{  label }}>
      <TagsWithDropdown {...{ tags }} />
    </MetaTagsBase>
  )
}


function MetaTagsBase({ label = undefined, children, className = undefined }) {
  return (
    <ul className={cx(styles.componentBase, className)}>
      {label && <TagBlack layoutClassName={styles.tagLayout} {...{ label }} />}
      {children}
    </ul>
  )
}

function Tags({ tags }) {
  return tags.filter(Boolean).map((x, i) => {
    if (Array.isArray(x.tags) && x.tags.length === 0)
      return null

    if (Array.isArray(x.tags) && x.tags.length === 1)
      return <TagPeach key={i} label={x.tags[0]} layoutClassName={styles.tagLayout} />

    if (Array.isArray(x.tags) && x.tags.length > 1)
      return <TagPeach key={i} label={x.label} layoutClassName={styles.tagLayout} />

    return <TagPeach key={i} label={x} layoutClassName={styles.tagLayout} />
  })
}

function TagsWithDropdown({ tags }) {
  return tags.filter(Boolean).map((x, i) => {
    if (Array.isArray(x.tags) && x.tags.length === 0)
      return null

    if (Array.isArray(x.tags) && x.tags.length === 1)
      return <TagPeach key={i} label={x.tags[0]} layoutClassName={styles.tagLayout} />

    if (Array.isArray(x.tags) && x.tags.length > 1)
      return <TagDropdown key={i} tags={x.tags} label={x.label} />

    return <TagPeach key={i} label={x} layoutClassName={styles.tagLayout} />
  })
}
