import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Tag.css'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'

export function TagBlack({ label, layoutClassName }) {
  return <TagBase className={cx(styles.componentBlack, layoutClassName)} {...{ label }} />
}

export function TagPeach({ label, layoutClassName }) {
  return <TagBase className={cx(styles.componentPeach, layoutClassName)} {...{ label }} />
}

export function TagWhiteTransparent({ label, layoutClassName }) {
  return <TagBase className={cx(styles.componentWhiteTransparent, layoutClassName)} {...{ label }} />
}

function TagBase({ label, className }) {
  return (
    <li className={cx(styles.componentBase, className)}>
      {label}
    </li>
  )
}

export function TagDropdown({ tags, label }) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <li className={styles.componentDropdown}>
      <button
        onClick={handleOpenChange}
        aria-label={isOpen ? 'Close tag dropdown' : 'Open tag dropdown'}
        data-x='click-to-toggle-tag-dropdown'
        className={styles.dropdownButton}
      >
        <label className={styles.dropdownButtonLabel}>{label}</label>
        <span className={cx(styles.iconContainer, isOpen && styles.isOpen)}>
          <Icon icon={iconChevronDown} layoutClassName={styles.iconLayout} />
        </span>
      </button>

      <span className={styles.labelsContainer}>
        {tags.map((label, i) => <span key={i} data-content={label} className={styles.labelWrapper} />)}
      </span>

      <DropdownList layoutClassName={styles.dropdownListLayout} {...{ tags, isOpen }} />
    </li>
  )

  function handleOpenChange() {
    setIsOpen(!isOpen)
  }
}

function DropdownList({ tags, isOpen, layoutClassName }) {
  return (
    <div aria-hidden={!isOpen} className={cx(styles.componentDropdownList, isOpen && styles.isOpen, layoutClassName)}>
      <ul className={styles.dropdownList}>
        {tags.map((label, i) => <DropdownLabel key={i} {...{ label }} />)}
      </ul>
    </div>
  )
}

function DropdownLabel({ label }) {
  return <li className={styles.componentDropdownLabel}>{label}</li>
}
